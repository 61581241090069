import React, { useEffect, useState } from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
} from "@ionic/react";
import { Route, Redirect } from "react-router";
import Home from "../Home/Home";
import Wallet from "../Wallet/Wallet";
import Expenses from "../Expenses/Expenses";
import Profile from "../Profile/Profile";
interface MainTabsProps {
  darkModeValue: string | null;
}

const MainTabs: React.FC<MainTabsProps> = ({ darkModeValue }) => {
  // const darkModeValue = localStorage.getItem("darkMode");
  const tabClassName = darkModeValue === 'darkMode' ? 'dark-mode-tabs' : 'light-mode-tabs';

  // const { setShowFab } = React.useContext(UIContext);

  useEffect(() => {
    console.log("darkModeValue", tabClassName);
  });

  const tabChanged = (e: any) => {
    console.log(e.detail.tab);
    // setSelectedTab(e.detail.tab);
  };

  return (

    <IonTabs onIonTabsDidChange={(e) => tabChanged(e)}>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/dashboard" />
        <Route path="/tabs/dashboard" render={() => <Home />} exact={true} />
        <Route path="/tabs/bookings" render={() => <Expenses />} exact={true} />
        <Route path="/tabs/services" render={() => <Wallet />} exact={true} />
        <Route path="/tabs/profile" render={() => <Profile />} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tabs/dashboard">
          <IonLabel>Dashboard</IonLabel>
        </IonTabButton>
        <IonTabButton tab="expenses" href="/tabs/bookings">
          <IonLabel>Bookings</IonLabel>
        </IonTabButton>
        <IonTabButton tab="wallet" href="/tabs/services">
          <IonLabel>Services</IonLabel>
        </IonTabButton>
        <IonTabButton tab="about" href="/tabs/profile">
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>

  );
};

export default MainTabs;
