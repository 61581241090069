import { FingerprintAIO } from '@ionic-native/fingerprint-aio';


class FingerprintService {
    fingerprint: typeof FingerprintAIO;

    constructor() {
        this.fingerprint = FingerprintAIO;
    }

    authenticate(): Promise<any> {
        return this.fingerprint.isAvailable()
            .then((availabilityStatus: any) => {
                console.log("Availability status:", availabilityStatus);
                if (availabilityStatus === "face" || availabilityStatus === "finger" || availabilityStatus === "biometric") {
                    return new Promise((resolve, reject) => {
                        this.fingerprint
                            .show({ description: "AFH Partner" })
                            .then((result_1) => {
                                console.log('Inside success', result_1);
                                resolve(result_1);
                            })
                            .catch((_e) => {
                                console.log('Inside failure on cancel', _e);
                                reject(_e);
                            });
                    });
                } else {
                    // Biometric authentication is not available
                    // Handle this scenario accordingly (e.g., show an error message)
                    return Promise.reject("Biometric authentication not available.");
                }
            });
    }
}

const fingerprintService = new FingerprintService();
export default fingerprintService;
