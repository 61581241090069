import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Getstarted from "./pages/Getstarted/Getstarted";
import Intro from "./pages/Intro/Intro";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import MainTabs from "./pages/Tabs/MainTabs";

import Requestmoney from "./pages/Requestmoney/Requestmoney";
import Requestreview from "./pages/Requestreview/Requestreview";
import Reviewandsend from "./pages/Reviewandsend/Reviewandsend";
import Sendmoney from "./pages/Sendmoney/Sendmoney";
import Successmodal from "./pages/Modals/Successmodal/Successmodal";
import Addincome from "./pages/Addincome/Addincome";
import Send from "./pages/Send/Send";
import Notifications from "./pages/Notifications/Notifications";
import Addproduct from "./pages/Addproduct/Addproduct";
import Viewbooking from "./pages/Viewbooking/Viewbooking";
import Notificationdetail from "./pages/Notificationdetail/Notificationdetails";
import Activehealthstatus from "./pages/Activehealthstatus/Activehealthstatus";
// import { PushNotifications } from '@capacitor/push-notifications';
import Viewprofile from "./pages/Request/Request";
// import firebase from 'firebase';
setupIonicReact({
  mode: "md",
});

const App: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");
  const tabClassName = darkModeValue === "darkMode" ? "dark-mode-tabs" : "light-mode-tabs";

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route
            path="/tabs"
            render={() => <MainTabs darkModeValue={tabClassName} />}
          />
          <Route exact path="/Getstarted">
            <Getstarted />
          </Route>
          <Route exact path="/intro">
            <Intro />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>

          <Route exact path="/Viewprofile">
            <Viewprofile />
          </Route>

          <Route exact path="/change-password">
            <Requestmoney />
          </Route>

          <Route exact path="/send">
            <Send />
          </Route>
          <Route exact path="/sendmoney">
            <Sendmoney />
          </Route>
          <Route component={Activehealthstatus} path="/Activehealthstatus">

          </Route>
          <Route component={Requestreview} path="/View-Categories">

          </Route>

          <Route path="/reviewandsend" component={Reviewandsend}>
          </Route>

          <Route exact path="/successmodal">
            <Successmodal />
          </Route>

          <Route exact path="/notifications">
            <Notifications />
          </Route>
          <Route exact path="/notificationDetail" component={Notificationdetail}>

          </Route>

          <Route path="/view-services" component={Addincome}>

          </Route>
          <Route path="/addproduct" component={Addproduct}>

          </Route>
          <Route path="/viewbooking" component={Viewbooking}>

          </Route>
          <Route exact path="/">
            <Redirect to="/Getstarted" />
          </Route>
        </IonRouterOutlet>
        {/* <IonFab
          onClick={() => setModalOpen(true)}
          style={fabButtonStyle}
          vertical="bottom"
          horizontal="center"
          slot="fixed"
        >
          <IonFabButton>
            <IonIcon icon={share} />
          </IonFabButton>
        </IonFab> */}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
