import {
  IonActionSheet,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
  getPlatforms,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import apiConfig from "../../apiConfig";
import "./Profile.css";
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

import { Network } from '@capacitor/network';

const Profile: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [isDarkModeActive, setIsDarkModeActive] = useState(Boolean);
  const [showToast, setShowToast] = useState(false);
  const [displayPicture, setDisplayPicture] = useState('');

  const [showLogoutCnfmModal, setShowLogoutCnfmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');
  const [showActionSheet, setShowActionSheet] = useState(false);
  const capturedImagesRef = useRef<File[]>([]);
  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');



  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useEffect(() => {



    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);

  const goBack = () => {
    history.goBack();
  };

  useIonViewWillEnter(() => {
    getUserDetails();

    setDisplayPicture(`${localStorage.getItem("displaypicture")}`);
    console.log("darkModeValue", localStorage.getItem("darkMode"));
    if (localStorage.getItem("darkMode") == "darkMode") {
      setIsDarkModeActive(true);
    } else {
      setIsDarkModeActive(false);
    }
  }, [])

  const handleToggleChange = (event: any) => {
    setShowToastSuccess(false);
    setShowToast(false);

    const isChecked = event.detail.checked;
    console.log("isChecked", isChecked);

    setIsDarkModeActive(isChecked);
    if (isChecked == true) {
      localStorage.setItem("darkMode", "darkMode");
    } else {
      localStorage.setItem("darkMode", "lightMode");
    }
  };

  const getUserDetails = () => {
    // setLoading(true);
    // setBackdropVisible(true);
    axios.post(`${apiConfig.baseUrl}/userdetails`, {
      "token": localStorage.getItem("fcmToken"),
      "partner_id": localStorage.getItem("partnerId")
    })
      .then(response => {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          // setLoading(false);
          // setBackdropVisible(false);
        } else {
          console.log("response", response.data.data);
          if (response.data.status == true) {
            // setLoading(false);
            // setBackdropVisible(false);
            localStorage.setItem("name", response.data.data.name);
            localStorage.setItem("tradingName", response.data.data.lastname);
            localStorage.setItem("email", response.data.data.email);
          }
        }
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        // setLoading(false);
        // setBackdropVisible(false);
      });
  }


  const handleUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];

        setLoading(true);
        setBackdropVisible(true);

        try {
          const blob = await file.arrayBuffer().then(data => new Blob([data]));
          const img = await createImageBitmap(blob);

          const canvas = document.createElement('canvas');
          // Resize image here:
          canvas.width = img.width > 1000 ? 1000 : img.width;
          canvas.height = img.width > 1000 ? Math.floor((img.height * 1000) / img.width) : img.height;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            console.error('Unable to get 2D context');
            return null;
          }
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const optimizedBlob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7)
          );

          if (!optimizedBlob) {
            console.error('Unable to create blob from canvas');
            return null;
          }

          const reader = new FileReader();
          const dataUrlPromise = new Promise<string>((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
          });
          reader.readAsDataURL(optimizedBlob);

          const dataUrl = await dataUrlPromise;

          const timestamp = new Date().getTime();
          const randomString = Math.random().toString(36).substring(2, 8);
          const fileName = `image_${timestamp}_${randomString}.jpg`;

          await Filesystem.writeFile({
            path: fileName,
            data: dataUrl,
            directory: Directory.Documents,
            recursive: true,
          });

          const fileObj = new File([optimizedBlob], fileName, { type: 'image/jpeg' });

          // setCapturedImage(fileObj); 
          handleSubmit(fileObj);
        } catch (error) {
          console.error('Error uploading image:', error);
        } finally {
          setLoading(false);
          setBackdropVisible(false);
        }
      }
    };

    input.click();
    setShowActionSheet(false);
  };
  const handleCapture = async () => {
    try {
      setLoading(true);
      setBackdropVisible(true);

      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      const imageUrl = Capacitor.convertFileSrc(image.webPath!);
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const img = await createImageBitmap(blob);

      const canvas = document.createElement('canvas');
      canvas.width = 800;
      canvas.height = 800 * (img.height / img.width);

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Unable to get 2D context');
      }
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const resizedBlob = await new Promise<Blob | null>((resolve) =>
        canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7)
      );

      if (!resizedBlob) {
        throw new Error('Unable to create blob from canvas');
      }

      const timestamp = new Date().getTime();
      const randomString = Math.random().toString(36).substring(2, 8);
      const fileName = `image_${timestamp}_${randomString}.jpg`;
      const file = new File([resizedBlob], fileName, { type: resizedBlob.type, lastModified: new Date().getTime() });

      // const updatedImages = [capturedImage, file];
      // setCapturedImage(file);
      handleSubmit(file);
    } catch (error) {
      console.error("Error during image capture and processing:", error);
    } finally {
      setLoading(false);
      setBackdropVisible(false);
      setShowActionSheet(false);
    }
  };
  const handleCancel = () => {
    setShowActionSheet(false);
  };
  const handleSubmit = async (fileObj: any) => {
    // event.preventDefault();
    setLoading(true);
    setBackdropVisible(true);
    const formData = new FormData();

    const token = localStorage.getItem("fcmToken") || "";
    formData.append('token', token);
    const partner_id = localStorage.getItem("partnerId") || "";
    formData.append('partner_id', partner_id);
    formData.append('file', fileObj);

    try {
      const response = await axios.post(`${apiConfig.baseUrl}/profilePhoto`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Form submitted successfully:', response.data);
      if (response.data.suspendstatus == false) {
        setSupendedMsg(response.data.message);
        setSupendedTitle(response.data.title);
        setShowSuspendedModal(true);
        setLoading(false);
        setBackdropVisible(false);
      } else {
        if (response.data.status == true) {
          localStorage.setItem("displaypicture", response.data.data);
          setDisplayPicture(response.data.data);
          formData.delete('token');
          capturedImagesRef.current = [];
          setLoading(false);
          setBackdropVisible(false);
          setShowToastSuccess(true);
          setSuccessMessage(response.data.message);
        } else {
          setLoading(false);
          setBackdropVisible(false);
          setErrorMessage(response.data.message);
          setShowToast(true);
        }
      }
      setTimeout(() => {
        if (loading == true) {
          setLoading(false);
          setBackdropVisible(false);
          // setErrorMessage("Something went wrong on API please try again");
          // setShowToast(true);
        }
      }, 10000);
    } catch (error) {
      setLoading(false);
      setBackdropVisible(false);
      console.error('Error submitting form:', error);
    }
  };
  const logout = () => {
    setLoading(true);
    setBackdropVisible(true);
    axios.post(`${apiConfig.baseUrl}/logout`, {
      "token": localStorage.getItem("fcmToken"),
    "partner_id": localStorage.getItem("partnerId")
    })
      .then(res => {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          if (res.data.status == true) {
            setLoading(false);
            setShowLogoutCnfmModal(false);
            setBackdropVisible(false);
            history.push('/login');
          } else {
            setLoading(false);
            // setBackdropVisible(false);
            // setErrorMessage(res.data.message);
            setShowToast(true);
          }
        }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }
  const handleActionSheet = () => {
    setShowActionSheet(true);
  };
  return (
    <IonPage>
      {networkStatus == false && isDarkModeActive == true &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="no-network-div center-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == true &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="center-div">
              <div className="background-holder"></div>
              <div className="profile-top-card dark-profile-top-card animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                <div className="img-holder ion-text-center">
                  <IonAvatar>
                    <IonImg src={displayPicture ? displayPicture : "assets/imgs/icons/dark-profile.svg"} ></IonImg>
                  </IonAvatar>
                  <IonIcon
                    className="fav header-icons profile-add"
                    onClick={handleActionSheet}
                    src="assets/imgs/icons/profileAdd.svg"
                    slot="end"
                  />
                  <div className="details">
                    <h5 style={{ color: "#CCCCCC" }}>
                      {localStorage.getItem("name")} {localStorage.getItem("tradingName")}
                    </h5>
                    <p style={{ color: "#CCCCCC" }}>{localStorage.getItem("email")}</p>
                    <p className="status-chip">Account Status: Good</p>
                  </div>
                </div>
              </div>
              <IonItem className="dark-item-mode-bg" lines="none">
                <div className="thumb" slot="start">
                  <img src="assets/imgs/icons/darkMode.svg" alt="" />
                </div>
                <IonLabel className="dark-profile-label">Dark Mode</IonLabel>
                <IonToggle
                  className="toggleActive"
                  slot="end"
                  checked={isDarkModeActive}
                  onIonChange={handleToggleChange}
                ></IonToggle>
              </IonItem>
              <div className="dark-bottom-section ion-padding-horizontal">
                <div className="general animate__animated animate__fadeInUp">
                  <h5 className="dark-bottom-section">General</h5>
                  <div className="gen-list">
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        onClick={() => window.open('mailto:support@anythingforhire.com')}
                      >
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/contactsupport.svg" alt="" />
                          </div>
                          <IonLabel className="dark-profile-label">Contact support</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/darkRightArrow.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        routerLink="/Viewprofile"
                        routerDirection="none"
                      >
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/editProfile.svg" alt="" />
                          </div>
                          <IonLabel className="dark-profile-label">Edit Profile</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/darkRightArrow.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        routerLink="/Activehealthstatus"
                        routerDirection="none"
                      >
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/AHS.svg" alt="" />
                          </div>
                          <IonLabel className="dark-profile-label">Account Health Status</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/darkRightArrow.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                      <IonButton
                        className="end-btn width100"
                        routerLink="/change-password"
                        routerDirection="none"
                      >
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/changePassword.svg" alt="" />
                          </div>
                          <IonLabel className="dark-profile-label">Change Password</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/darkRightArrow.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                  </div>
                </div>
                <div className="chart animate__animated animate__fadeInUp">
                  <h5 className="dark-bottom-section">Notification</h5>
                  <div className="chart-list">
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        routerLink="/notifications"
                        routerDirection="none"
                      >
                        <IonItem lines="none" className="dark-profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/notification.svg" alt="" />
                          </div>
                          <IonLabel className="dark-profile-label">Notifications</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/darkRightArrow.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        onClick={() => setShowLogoutCnfmModal(true)}
                      >
                        <IonItem lines="none" style={{ marginBottom: "10px" }} className="dark-profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/logout.svg" alt="" />
                          </div>
                          <IonLabel className="dark-profile-label">Logout</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/darkRightArrow.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                  </div>
                </div>
              </div>
            </div>
            <IonModal isOpen={showLogoutCnfmModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>Logout!</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>Are you sure you want to Logout?</IonLabel>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={() => setShowLogoutCnfmModal(false)} className='fontName dark-reject-btn' type='submit'>
                        Cancel
                      </IonButton>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={logout} className='fontName booking-details-btn' id="open-customa-dialog" expand="block">
                        Okay
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => { setShowSuspendedModal(false); setShowLogoutCnfmModal(false) }}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success"
            />
            <IonActionSheet
              isOpen={showActionSheet}
              className="dark-action-sheet"
              onDidDismiss={() => setShowActionSheet(false)}
              buttons={[
                ...(getPlatforms().includes('desktop') ? [] : [{
                  text: 'Capture',
                  handler: handleCapture,
                }]),
                {
                  text: 'Upload',
                  handler: handleUpload,
                },
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: handleCancel,
                },
              ]}
            ></IonActionSheet>
          </IonContent>
        </>
      }
      {networkStatus == false && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile">
            <div className="center-div">
              <div className="no-network-div">
                <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                <h6 className='fontName'>Check your internet connection and try again</h6>
              </div>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile">
            <div className="center-div">
              <div className="background-holder"></div>
              <div className="profile-top-card animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                <div className="img-holder ion-text-center">
                  <IonAvatar>
                    <IonImg src={displayPicture ? displayPicture : "assets/imgs/icons/light-profile.svg"} ></IonImg>
                  </IonAvatar>
                  <IonIcon
                    onClick={handleActionSheet}
                    className="fav header-icons profile-add"
                    src="assets/imgs/icons/profileAdd.svg"
                    slot="end"
                  />
                  <div className="details">
                    <h5>
                      {localStorage.getItem("name")} {localStorage.getItem("tradingName")}
                    </h5>
                    <p>{localStorage.getItem("email")}</p>
                    <p className="status-chip">Account Status: Good</p>
                  </div>
                </div>
              </div>
              <IonItem className="dark-mode-bg" lines="none">
                <div className="thumb" slot="start">
                  <img src="assets/imgs/icons/darkMode.svg" alt="" />
                </div>
                <IonLabel className="profile-label">Dark Mode</IonLabel>
                <IonToggle
                  className="toggleActive"
                  slot="end"
                  checked={isDarkModeActive}
                  onIonChange={handleToggleChange}
                ></IonToggle>
              </IonItem>
              <div className="bottom-section ion-padding-horizontal">
                <div className="general animate__animated animate__fadeInUp">
                  <h5>General</h5>
                  <div className="gen-list">
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton className="end-btn width100" onClick={() => window.open('mailto:support@anythingforhire.com')}>
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/contactsupport.svg" alt="" />
                          </div>
                          <IonLabel className="profile-label">Contact support</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/rightArrowBlk.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        routerLink="/Viewprofile"
                        routerDirection="none"
                      >
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/editProfile.svg" alt="" />
                          </div>
                          <IonLabel className="profile-label">Edit Profile</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/rightArrowBlk.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        routerLink="/Activehealthstatus"
                        routerDirection="none"
                      >
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/AHS.svg" alt="" />
                          </div>
                          <IonLabel className="profile-label">Account Health Status</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/rightArrowBlk.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                      <IonButton
                        className="end-btn width100"
                        routerLink="/change-password"
                        routerDirection="none"
                      >
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/changePassword.svg" alt="" />
                          </div>
                          <IonLabel className="profile-label">Change Password</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/rightArrowBlk.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                  </div>
                </div>

                <div className="chart animate__animated animate__fadeInUp">
                  <h5>Notification</h5>
                  <div className="chart-list">
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        routerLink="/notifications"
                        routerDirection="none"
                      >
                        <IonItem lines="none" className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/notification.svg" alt="" />
                          </div>
                          <IonLabel className="profile-label">Notifications</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/rightArrowBlk.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end" className="mrgn-btn">
                      <IonButton
                        className="end-btn width100"
                        onClick={() => setShowLogoutCnfmModal(true)}
                      >
                        <IonItem lines="none" style={{ marginBottom: "10px" }} className="profile-ion-item width100">
                          <div className="thumb" slot="start">
                            <img src="assets/imgs/icons/logout.svg" alt="" />
                          </div>
                          <IonLabel className="profile-label">Logout</IonLabel>
                          <IonImg slot="end" src="assets/imgs/icons/rightArrowBlk.svg"></IonImg>
                        </IonItem>
                      </IonButton>
                    </IonButtons>
                  </div>
                </div>
              </div>
            </div>
            {/* <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            /> */}
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal isOpen={showLogoutCnfmModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>Logout!</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>Are you sure you want to Logout?</IonLabel>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={() => setShowLogoutCnfmModal(false)} className='fontName reject-btn' type='submit'>
                        Cancel
                      </IonButton>
                    </IonCol>
                    <IonCol size='6' col-6>
                      <IonButton style={{ width: "100%", margin: "auto" }} onClick={logout} className='fontName booking-details-btn' id="open-customa-dialog" expand="block">
                        Okay
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false); setShowLogoutCnfmModal(false) }} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonActionSheet
              isOpen={showActionSheet}
              onDidDismiss={() => setShowActionSheet(false)}
              buttons={[
                ...(getPlatforms().includes('desktop') ? [] : [{
                  text: 'Capture',
                  handler: handleCapture,
                }]),
                {
                  text: 'Upload',
                  handler: handleUpload,
                },
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: handleCancel,
                },
              ]}
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
          </IonContent>
        </>
      }
    </IonPage>
  );
};

export default Profile;
