import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { Swiper, SwiperSlide } from 'swiper/react';


import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from "swiper";

import "./Intro.css";

import "@ionic/react/css/ionic-swiper.css";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useLocation } from "react-router";
import { Network } from '@capacitor/network';
import { useEffect, useState } from "react";

const Intro: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);
  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);
  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <div className="no-network-div">
          <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
          <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
        </div>
      }
      {networkStatus == true && darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="end">
                <IonButton routerLink="/login" className="dark-skip-button">Skip</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding intro dark-mode-ion-content" fullscreen>
            <Swiper
              modules={[Autoplay, Pagination]}
              autoplay={true}
              pagination={{
                clickable: true,
                bulletClass: 'swiper-pagination-bullet',
                bulletActiveClass: 'swiper-pagination-bullet-active',
              }}
            >
              <SwiperSlide>
                <IonRow>
                  <IonCol col-12 size="12">
                    <div className="img-holder ion-text-center animate__animated animate__bounce">
                      <IonImg className="slide-img" src="assets/imgs/images/first.svg" alt="First Img"> </IonImg>
                    </div>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="slide-content ion-text-center ion-padding-horizontal">
                      <h4 className="sm-title dark-intro-h4 ion-text-wrap">
                        Grow Your Business
                      </h4>
                      <p className="dark-intro-msg ion-text-wrap">
                        Unlock new opportunities, expand your fleet, and increase revenue with our exclusive partner growth resources.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </SwiperSlide>
              <SwiperSlide>
                <IonRow>
                  <IonCol col-12 size="12">
                    <div className="img-holder ion-text-center animate__animated animate__bounce">
                      <IonImg className="slide-img" src="assets/imgs/images/second.svg" alt="second Img"> </IonImg>
                    </div>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="slide-content ion-text-center ion-padding-horizontal">
                      <h4 className="sm-title dark-intro-h4 ion-text-wrap">
                        Manage Bookings on the Go
                      </h4>
                      <p className="dark-intro-msg ion-text-wrap">
                        Seamlessly handle bookings, track reservations, and access real-time updates from anywhere, ensuring efficient operations.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </SwiperSlide>
              <SwiperSlide>
                <IonRow>
                  <IonCol col-12 size="12">
                    <div className="img-holder ion-text-center animate__animated animate__bounce">
                      <IonImg className="slide-img" src="assets/imgs/images/third.svg" alt="third Img"> </IonImg>
                    </div>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="slide-content ion-text-center ion-padding-horizontal">
                      <h4 className="sm-title dark-intro-h4 ion-text-wrap">
                        Know How Much You Earned
                      </h4>
                      <p className="dark-intro-msg ion-text-wrap">
                        Gain instant insights into your earnings, review detailed revenue reports, and optimize your luxury transport business.
                      </p>
                    </div>
                    <div className="btns-holder ion-text-center">
                      <IonButton routerLink="/login" className="next-btn btn">Go To Login</IonButton>
                    </div>
                  </IonCol>

                </IonRow>
              </SwiperSlide>
            </Swiper>
          </IonContent>
        </>
      }
      {networkStatus == false && darkModeValue == "lightMode" &&
        <div className="no-network-div">
          <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
          <h6 className='fontName'>Check your internet connection and try again</h6>
        </div>
      }
      {networkStatus == true && darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="end">
                <IonButton routerLink="/login" className="skip-button">Skip</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding intro light-mode-ion-content" fullscreen>
            <Swiper
              modules={[Autoplay, Pagination]}
              autoplay={true}
              pagination={true}
            >
              <SwiperSlide>
                <IonRow>
                  <IonCol col-12 size="12">
                    <div className="img-holder ion-text-center animate__animated animate__bounce">
                      <IonImg className="slide-img" src="assets/imgs/images/dark-first.svg" alt="First Img"> </IonImg>
                    </div>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="slide-content ion-text-center ion-padding-horizontal">
                      <h4 className="sm-title intro-h4 ion-text-wrap">
                        Grow Your Business
                      </h4>
                      <p className="intro-msg ion-text-wrap">
                        Unlock new opportunities, expand your fleet, and increase revenue with our exclusive partner growth resources.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </SwiperSlide>
              <SwiperSlide>
                <IonRow>
                  <IonCol col-12 size="12">
                    <div className="img-holder ion-text-center animate__animated animate__bounce">
                      <IonImg className="slide-img" src="assets/imgs/images/dark-second.svg" alt="second Img"> </IonImg>
                    </div>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="slide-content ion-text-center ion-padding-horizontal">
                      <h4 className="sm-title intro-h4 ion-text-wrap">
                        Manage Bookings on the Go
                      </h4>
                      <p className="intro-msg ion-text-wrap">
                        Seamlessly handle bookings, track reservations, and access real-time updates from anywhere, ensuring efficient operations.
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </SwiperSlide>
              <SwiperSlide>
                <IonRow>
                  <IonCol col-12 size="12">
                    <div className="img-holder ion-text-center animate__animated animate__bounce">
                      <IonImg className="slide-img" src="assets/imgs/images/dark-third.svg" alt="third Img"> </IonImg>
                    </div>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="slide-content ion-text-center ion-padding-horizontal">
                      <h4 className="sm-title intro-h4 ion-text-wrap">
                        Know How Much You Earned
                      </h4>
                      <p className="intro-msg ion-text-wrap">
                        Gain instant insights into your earnings, review detailed revenue reports, and optimize your luxury transport business.
                      </p>
                    </div>
                    <div className="btns-holder ion-text-center">
                      <IonButton routerLink="/login" className="next-btn btn">Go To Login</IonButton>
                    </div>
                  </IonCol>

                </IonRow>
              </SwiperSlide>
            </Swiper>
          </IonContent>
        </>
      }
    </IonPage>
  );
};

export default Intro;
