
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

export const firebaseConfig = {
    apiKey: "AIzaSyBLSesE8FKxLRe7-Mepgiuv1L2Fy6XM8YA",
    authDomain: "afh-partner-app-387814.firebaseapp.com",
    databaseURL: "https://afh-partner-app-387814-default-rtdb.firebaseio.com",
    projectId: "afh-partner-app-387814",
    storageBucket: "afh-partner-app-387814.appspot.com",
    messagingSenderId: "600535372172",
    appId: "1:600535372172:web:c1de0565ba3b05383b06a9",
    measurementId: "G-3VGMDMK8KX"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export default messaging;